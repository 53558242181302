<svelte:options tag="down-img" />

<script>
  import axios from "axios";
  import { onMount, onDestroy } from "svelte";

  export let url = "";
  export let id = "";
  export let savefilename = "";

  // Observe url Change
  $: url && urlChange();

  const urlChange = () => {
    mountStart();
  };

  // Internal Variables
  let showProgress = "none";
  let showImg = "none";
  let progressWidth = 0;
  let imgResult = null;
  let isLoading = false;
  let imgHref = null;
  let count = 0;
  let canSource = axios.CancelToken.source();

  async function loadmData(source) {

    showImg = "none";
    progressWidth = 0;
    showProgress = "block";
    imgResult = null;
    isLoading = true;
    imgHref = null;
    console.log(count);


    await new Promise((r) => setTimeout(r, 5000));
    
    axios({
      method: "get",
      url: source,
      responseType: "blob",
      cancelToken: canSource.token,
      onDownloadProgress: (progressEvent) => {
        console.log(progressEvent);
        //const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
        //const current = progressEvent.currentTarget.response.length
        const total = parseFloat(progressEvent.total);
        const current = parseFloat(progressEvent.loaded);
        progressWidth = Math.floor((current / total) * 100);
        console.log("Progress : " + progressWidth);
      },
    })
      .then((response) => {
        // console.log(response);
        imgResult = response.data;
        // console.log(imgResult);
        // Convert the Image Data to URL Data
        //var refdata = await blobToBase64(imgResult);
        const urlCreator = window.URL || window.webkitURL;
        const href = urlCreator.createObjectURL(imgResult);
        console.log(href);
        imgHref = href;
        showImg = "block";
        showProgress = "none";
        isLoading = false;
      })
      .catch((error) => {
        showProgress = "none";
        showImg = "none";
        imgHref = null;
        imgResult = null;
        isLoading = false;

        console.log(error);
        if (error.message) {
          if (error.message === "canceled") {
            console.log("Request Canceled !");
          } else {
            alert("Error opening Image File !");
          }
        }
      });
  }

  //   onMount(() => {
  //     mountStart();
  //   });

  onDestroy(() => {
    if (isLoading === true) {
      isLoading = false;
      canSource.cancel();

      canSource = axios.CancelToken.source;
    }
  });

  function mountStart() {
    console.log("is loading"+isLoading  );
    if (isLoading === true) {
      isLoading = false;
      canSource.cancel();
      console.log("Request Canceled !");
      canSource = axios.CancelToken.source;
    }

    if (url !== undefined && url !== null && url.length > 0) {
      console.log("Save file Name " + savefilename);
      loadmData(url);
    } else {
      if (isLoading === true) {
        isLoading = false;
        canSource.cancel();
        console.log("Request Canceled !");
        canSource = axios.CancelToken.source;
      }
    }
  }

  //   mountStart();
</script>

<main>
  <div class="map-holder">
    <img src={imgHref} id="imgImage" alt="" style="display: {showImg};" />

    <a
      href={imgHref}
      target="_blank"
      download={savefilename}
      style="display: {showImg};"
      alt="Image File">Download</a
    >

    <div class="progress-holder" id="phold" style="display: {showProgress};">
      <div class="pp" id="pp" style="width: {progressWidth}%;"></div>
    </div>
  </div>
</main>

<style>
  main {
    display: block;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
  }

  .map-holder {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    overflow-y: auto;
  }

  a {
    font-family: "Roboto Condensed", sans-serif;
    display: block;
    position: fixed;
    right: 40px;
    bottom: 40px;
    background: #51ff00;
    color: #00552a;
    padding: 0.75em;
    border-radius: 0.25em;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }

  a:hover {
    text-decoration: none;
    background: #00552a;
    color: #51ff00;
    transition: all 0.3s ease-in-out;
  }

  .progress-holder {
    display: block;
    position: fixed;
    width: calc(100% - 70px);
    left: 20px;
    height: 15px;
    border-radius: 15px;
    top: calc(50vh - 15px);
    background: rgba(#0000006e, 1);
    padding: 5px;
  }
  .progress-holder .pp {
    position: relative;
    border-radius: 5px;
    height: 5px;
    background: #51ff00;
    width: 0%;
  }
</style>
