import App from './App.svelte';

// const app = new App({
// 	target: document.body,
// 	props: {
// 		url: 'https://scan.hooghly.landacquisition.in/api/getjpgfile?MAP_FILE_ID=54'
// 	}
// });
const app = new App();

export default app;